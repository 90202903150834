import React, { Fragment } from 'react';
import ShortRuleHeadline from 'src/components/ShortRuleHeadline';
import VideoPlayer from 'src/components/VideoPlayer';
import ReadMoreLink from 'src/components/ReadMoreLink';
import DownloadButton from 'src/components/DownloadButton';
import { hasResources } from 'src/utils/helpers';
import PropTypes from 'prop-types';

import {
  ContainerWrapper,
  Headline,
  VideosWrapper,
  Grid,
  GridColumn,
  VideosGrid,
} from 'src/components/ResourcesStyles';

export default function ResourcesSection({
  color,
  title,
  components,
  index,
  videosOnly = false,
  lang,
  homePage,
}) {
  const renderShortRuleHeadline = (
    { title: nodeTitle, designPagesModules },
    number,
    i
  ) => {
    const isFirst = i === 0 && index > 0;
    return (
      <ContainerWrapper color={color} isFirst={isFirst}>
        <ShortRuleHeadline
          key={i}
          slug={`${title}${number ? ` - ${lang === 'en' ? 'Module' : 'Módulo'} ${number}` : ''}`}
          headline={nodeTitle}
          text={designPagesModules.body}
        />
      </ContainerWrapper>
    );
  };

  const renderVideo = (
    {
      videoId,
      title,
      mediaType,
      coverImage,
      coverVideo,
      eyebrow,
      videosRoute,
      addHomeVideo,
    },
    i
  ) => {
    if (addHomeVideo) {
      const {
        contentSection: { homeVideo, homeCoverVideo },
      } = homePage;

      videoId = homeVideo;
      coverVideo = homeCoverVideo;
      mediaType = 'video';
    }

    return (
      <Fragment key={i}>
        {videoId && (
          <GridColumn index={i}>
            <VideoPlayer
              key={i}
              eyebrow={eyebrow}
              poster={mediaType === 'video' ? coverVideo : coverImage}
              posterType={mediaType}
              title={title}
              videoId={videoId}
              videosRoute={videosRoute}
            />
          </GridColumn>
        )}
      </Fragment>
    );
  };

  const renderVideos = (videos, i) => {
    return (
      <VideosWrapper key={i} color={color}>
        <ContainerWrapper>
          <Headline>{lang === 'en' ? 'Videos' : 'Vídeos'}</Headline>
          <VideosGrid>{videos.map(renderVideo)}</VideosGrid>
        </ContainerWrapper>
      </VideosWrapper>
    );
  };

  const renderReadMoreLink = (readMoreProps, i) => {
    return (
      <GridColumn key={i} index={i}>
        <ReadMoreLink {...readMoreProps} mainColor={color} useResourceStyles />
      </GridColumn>
    );
  };

  const renderReadMoreLinks = (readMoreLinks, isDiveDeep) => {
    return (
      <ContainerWrapper color={color}>
        <Headline style={{ marginBottom: '40px' }}>
          {isDiveDeep
            ? lang === 'en'
              ? 'Dive Deep'
              : 'Profundiza'
            : lang === 'en'
            ? 'More Resources On This Topic'
            : 'Más Recursos Sobre Este Tema'}
        </Headline>
        <Grid>{readMoreLinks.map(renderReadMoreLink)}</Grid>
      </ContainerWrapper>
    );
  };

  const renderDownloadButton = (downloadableProps, i) => {
    return (
      <GridColumn key={i} index={i}>
        <DownloadButton
          {...downloadableProps}
          useResourceStyles
          pageColors={{ mainColor: color }}
        />
      </GridColumn>
    );
  };

  const renderDownloadButtons = (downloadable) => {
    return (
      <ContainerWrapper color={color}>
        <Headline style={{ marginBottom: `40px` }}>
          {lang === 'en' ? 'Downloadable Materials' : 'Materiales Descargables'}
        </Headline>
        <Grid>{downloadable.map(renderDownloadButton)}</Grid>
      </ContainerWrapper>
    );
  };

  const renderComponent = (
    { node, video, moreResources, diveDeep, downloadable, number },
    i
  ) => {
    return (
      <div key={i} id={node.slug}>
        {node && renderShortRuleHeadline(node, number, i)}
        {video && video.length > 0 && renderVideos(video)}
        {moreResources &&
          !videosOnly &&
          moreResources.length > 0 &&
          renderReadMoreLinks(moreResources, false)}
        {diveDeep &&
          !videosOnly &&
          diveDeep.length > 0 &&
          renderReadMoreLinks(diveDeep, true)}
        {downloadable &&
          !videosOnly &&
          downloadable.length > 0 &&
          renderDownloadButtons(downloadable)}
      </div>
    );
  };

  return videosOnly
    ? components.filter((el) => el.video.length).map(renderComponent)
    : components.filter((el) => hasResources(el)).map(renderComponent);
}

ResourcesSection.propTypes = {
  index: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  components: PropTypes.array,
  videosOnly: PropTypes.bool,
};
