import React from 'react';
import Seo from 'src/components/Seo';
import { graphql } from 'gatsby';
import Hero from 'src/components/Hero';
import DropDown from 'src/components/DropDown';
import Link from 'src/components/Link';
import ResourcesSection from 'src/components/ResourcesSection';
import { ContainerWrapper, Button } from 'src/components/ResourcesStyles';
import { hasResources } from '../utils/helpers';

const ResourcesTemplate = ({ data, pageContext }) => {
  const {
    resources,
    pageBy: { homePage },
    heroSmall,
    heroLarge,
  } = data;

  const { videosOnly, lang } = pageContext;
  const alt = lang === 'en' ? 'A small group of students gathered at a table' : 'Un pequeño grupo de estudiantes reunidos en una mesa';

  const resourceFetch = (mod, type) => {
    if (!mod.node.designPagesModules.blocks) return [];
    return [
      ...mod.node.designPagesModules.blocks.filter(
        (block) =>
          block.fieldGroupName ===
          `DesignPage_Designpagesmodules_ContentBlocks_${type}`
      ),
      ...[].concat(
        ...resources.edges
          .filter(({ node }) => node.parentId === mod.node.id)
          .map((subMod) =>
            subMod.node.designPagesModules.blocks.filter(
              (block) =>
                block.fieldGroupName ===
                `DesignPage_Designpagesmodules_ContentBlocks_${type}`
            )
          )
      ),
    ];
  };

  const parents = resources.edges.filter(({ node }) => !node.parentId);

  const readMoreParser = (mod, diveDeep) => {
    const readMore = resourceFetch(mod, 'ReadMoreLinks');

    return [].concat(
      ...readMore
        .filter(({ headline }) => {
          const headlineTest = ['Dive Deep', 'Profundiza'].indexOf(headline);
          return diveDeep ? headlineTest !== -1 : headlineTest === -1;
        })
        .map(({ items }) =>
          items?.filter(
            ({ link, excludeFromResources }) => link && !excludeFromResources
          )
        )
    );
  };

  let modNumber = 0;

  const sorted = parents.map((parent) => {
    parent.children = resources.edges.filter(
      ({ node }) => node.parentId === parent.node.id
    );
    parent.children.map((mod) => {
      mod.video = resourceFetch(mod, 'Video');
      mod.video = mod.video.map((video) => {
        video.videosRoute = videosOnly;
        return video;
      });
      mod.diveDeep = readMoreParser(mod, true);
      mod.moreResources = readMoreParser(mod, false);
      mod.downloadable = [
        ...resourceFetch(mod, 'BlockText'),
        ...resourceFetch(mod, 'ActivityBlock'),
      ]
        .filter(({ showButton }) => showButton)
        .map((block) => {
          const { buttonLabel, buttonPDF } = block;
          return {
            buttonLabel: buttonLabel,
            buttonUrl: buttonPDF && buttonPDF.localFile.publicURL,
          };
        });
      if (
        ['Begin', 'Comienza'].indexOf(parent.node.title) !== -1 ||
        ['Capstone Activity', 'Actividad Final'].indexOf(mod.node.title) !== -1
      ) {
        mod.number = 0;
      } else {
        modNumber++;
        mod.number = modNumber;
      }
      return mod;
    });
    return parent;
  });

  const renderJumpLinks = () => {
    const options = [];
    sorted.forEach(({ children }) => {
      const sortedChildren = videosOnly
        ? children.filter((mod) => mod.number && mod.video.length)
        : children.filter((mod) => mod.number && hasResources(mod));

      sortedChildren.forEach(({ node, number }, i) => {
        options.push({ title: `${number} - ${node.title}`, value: node.slug });
      });
    });
    return (
      <ContainerWrapper color="green">
        <DropDown
          placeholder={lang === 'en' ? 'JUMP TO MODULE' : 'SALTAR AL MÓDULO'}
          options={options}
          onChange={(selectNode) => {
            document.querySelector(`#${selectNode.value}`).scrollIntoView({
              behavior: 'instant',
            });
            selectNode.value = 0;
          }}
        />
        <Link to={`/${lang === 'en' ? 'resources/' : 'es/recursos/'}${videosOnly ? '' : 'videos/'}`}>
          <Button type="button">
            {videosOnly ? (lang === 'en' ? 'View all Resources' : 'Ver todos los Recursos') : (lang === 'en' ? 'View all Videos' : 'Ver todos los videos')}
          </Button>
        </Link>
      </ContainerWrapper>
    );
  };

  return (
    <>
      <Seo title={`Resources${videosOnly ? ': Videos' : ''} - XQ In A Box`} />
      <Hero
        headline={`${lang === 'en' ? 'Resources' : 'Recursos'}${videosOnly ? `: ${lang === 'en' ? 'Videos' : 'Vídeos'}` : ''}`}
        text={lang === 'en' ? 'How can schools motivate and empower young people to take responsibility for their own learning?' : '¿Cómo pueden las escuelas motivar y capacitar a los jóvenes para que asuman la responsabilidad de su propio aprendizaje?'}
        image={heroSmall ? {
          small: { ...heroSmall, alt },
          large: { ...heroLarge, alt },
        } : {
          image: { ...heroLarge, alt }
        }}
        type="image"
      />
      <div>
        {renderJumpLinks()}
        {sorted.map(({ node, children }, i) => (
          <ResourcesSection
            key={i}
            index={i}
            videosOnly={videosOnly}
            lang={lang}
            title={node.title}
            color={node.designPagesModules.colors.mainColor}
            components={children}
            homePage={homePage}
          />
        ))}
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query resourceQuery($lang: String!) {
    pageBy: wpPage(uri: {eq: "/"}) {
      homePage {
        contentSection {
          homeVideo: video
          homeCoverVideo: covervideo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    resources: allWpDesignPage(filter: {language: {slug: {eq: $lang}}},sort: {fields: menuOrder, order: ASC}) {
      edges {
        node {
          slug
          title
          id
          parentId
          language {
            slug
          }
          designPagesModules {
            body
            colors: colorTheme {
              mainColor
            }
            blocks: contentBlocks {
              ...Text
              ...VideoCaseStudy
              ...ReadMoreBlock
              ...ActivityBlock
            }
          }
        }
      }
    }
    heroSmall: file(relativePath: {eq: "resources-hero-mobile.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 376, layout: CONSTRAINED)
      }
    }
    heroLarge: file(relativePath: {eq: "resources-hero.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default ResourcesTemplate;
