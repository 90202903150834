import React from 'react'
import styled from '@emotion/styled'
import { animations, colors, typography, mediaQueries as mq } from 'src/styles'
import ArrowDownIcon from 'src/svg/icon-arrow-down.svg'
import PropTypes from 'prop-types'

const DropDownWrapper = styled.div`
  background-color: ${colors.green};
  display: inline-block;
  transition: background-color ${ animations.mediumSpeed } ease-in-out;

  &:hover {
    background-color: ${colors.greenDarken};
  }
`
const DropDownSelectWrapper = styled.div`
  color: ${colors.white};
  position: relative;
`
const DropDownSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: ${typography.fontSizeMedium};
  font-weight: 500;
  color: inherit;
  padding: 9px 30px 9px 10px;
  border-radius: 0;
  cursor: pointer;
  max-width: 110px;

  ${ mq.mediumAndUp } {
    padding: 13px 60px 13px 22px;
    max-width: 225px;
  }

  &::-ms-expand {
    display: none;
  }
`

const DropDownSelectIcon = styled(ArrowDownIcon)`
  width: 9px;
  height: 6px;
  position: absolute;
  top: 18px;
  right: 12px;
  pointer-events: none;

  ${ mq.mediumAndUp } {
    top: 16px;
    right: 20px;
    width: 15px;
    height: 13px;
  }
`

export default function DropDown({ placeholder='', options=[], onChange}) {
  const renderOption = ({title, value}, i) => {
    return <option key={i} value={value}>{title}</option>
  }

  const handleChange = (e) => {
    if(onChange) {
      onChange(e.target)
    }
  }

  return (
    <DropDownWrapper>
      <DropDownSelectWrapper>
        <DropDownSelect defaultValue={0} onChange={handleChange}>
          <option value={0} disabled>{placeholder}</option>
          {options.map(renderOption)}
        </DropDownSelect>
        <DropDownSelectIcon />
      </DropDownSelectWrapper>
    </DropDownWrapper>
  )
}

DropDown.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
}
