import styled from '@emotion/styled'
import { animations, colors, typography, mediaQueries as mq } from 'src/styles'
import Container from 'src/components/Container'
import GridStyles from 'src/components/GridStyles'
import BgSelector from 'src/components/BgSelector'

export const ContainerWrapper = styled(Container)`
  color: ${ ({color = 'currentColor'}) => colors[color] };
  ${ ({ isFirst }) => isFirst ? `
    margin: 80px auto 40px;
    ${ mq.largeAndUp } {
      margin: 120px auto 75px;
    }
  ` : `
    margin: 40px auto;
    ${ mq.largeAndUp } {
      margin: 75px auto;
    }
  `
  }
`
export const Headline = styled.h3`
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;
  color: currentColor;

  ${ mq.largeAndUp } {
    font-size: 36px;
  }
`
export const VideosWrapper = styled(BgSelector)`
  padding: 30px 0;
  color: ${ ({color = 'currentColor'}) => colors[color] };

  ${ mq.largeAndUp } {
    padding: 40px 0 75px;
  }

  ${ContainerWrapper} {
    margin: 0 auto;

    ${ mq.largeAndUp } {
      margin: 0 auto;
    }
  }
`
export const Grid = styled(GridStyles.Grid)`
`

export const GridColumn = styled(GridStyles.GridColumn)`
  ${ mq.largeAndUp } {
    -ms-grid-column: ${({index}) => (index%2 * 6) + 1};
    -ms-grid-row: ${({index}) => Math.floor(index/2) + 1};
    -ms-grid-column-span: 6;
    grid-column: span 6;
  }

  ${ mq.largerAndUp } {
    -ms-grid-column: ${({index}) => (index%3 * 4) + 1};
    -ms-grid-row: ${({index}) => Math.floor(index/3) + 1};
    -ms-grid-column-span: 4;
    grid-column: span 4;
  }
`
export const VideosGrid = styled(Grid)`
  margin: 0 -20px;

  ${ mq.mediumAndUp } {
    margin: 0 0;
  }
`

export const Button = styled.button`
  display: inline-block;
  appearance: none;
  border: 1px solid currentColor;
  background-color: transparent;
  color: ${colors.green};
  margin-left: 10px;
  outline: 0;
  padding: 10px;
  font-size: ${typography.fontSizeMedium};
  font-weight: 500;
  text-transform: uppercase;
  transition: background-color ${ animations.mediumSpeed } ease-in-out,
    color ${ animations.mediumSpeed } ease-in-out;
  cursor: pointer;

  ${ mq.mediumAndUp } {
    margin-left: 20px;
    padding: 13px 22px;
  }

  &:hover {
    background-color: ${colors.green};
    color: white;
  }
`